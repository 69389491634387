body {
  background-color: #f7f7f7;
  font-size: 15px;
  line-height: 1.1;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

input {
  /*background-color: transparent !important;*/
  border: none !important;
  border-bottom: 1px solid #c4c4c4 !important;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08) !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
}

.no-border-bottom {
  border-bottom: 0 !important;
}

.yellow-notes {
  background-color: #ffffd3 !important;
}

::placeholder {
  color: rgba(0, 0, 0, 0.15) !important;
}

.input-group-text {
  background-color: rgba(217, 217, 217, 0.3);
  /*font-weight: lighter;*/
  font-size: x-small !important;
  opacity: 0.8;
}

/*input:focus {*/
/*  !*border-bottom: 1.2px solid #4293d5 !important;*!*/
/*}*/

/*textarea.form-control:hover, input.form-control:hover {*/
/*  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1) !important;*/
/*  -moz-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1) !important;*/
/*  -webkit-box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1) !important;*/
/*}*/

input:focus,
/*button:hover,*/
textarea:focus,
textarea.form-control:focus,
textarea.form-control:hover,
input.form-control:hover,
select:focus,
select:hover {
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.15) !important;
}

button.close {
  /*font-size: 15px;*/
  border-radius: 10px;
  box-shadow: none !important;
}

/*.btn {*/
/*  !*border-radius: 0.25rem;*!*/
/*  box-shadow: 1px 1px 2px rgba(13,19,26,0.08), 0 1px 3px rgba(0,0,0,0.08);*/
/*}*/

/*button:focus {*/
/*  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2) !important;*/
/*  -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2) !important;*/
/*  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2) !important;*/
/*}*/

/*input.form-control {*/
/*  margin-bottom: 12px;*/
/*}*/

.dash-col-lowpad {
  padding-left: 6px;
  padding-right: 6px;
}

a i {
  /*margin-left: 20px;*/
  color: #b0b0b0 !important;
  transition: all 0.3s;
}

a i:hover {
  color: #535353 !important;
}

/*.nav {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*}*/

.nav a {
  /*color: #ffffff;*/
  color: #939393;
  /*display: inline-block;*/
}

.nav a:hover {
  color: #000000;
}

.nav ul li {
  transition: background-color 0.3s;
}

.nav li a {
  /*padding: 8px;*/
  transition: all 0.3s;
}

.nav li a:hover {
  color: #232323 !important;
  background-color: #efefef;
}

.nav-link {
  /*padding: 14px 8px !important;*/
  /*border-top-left-radius: 6px;*/
  /*border-top-right-radius: 6px;*/
  border-radius: 6px;
}

.nav-link.active {
  background-color: #e8e8e8;
  color: #000000 !important;
  transition: all 0.5s;
  /*border: #a1a1a1 1px solid;*/
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
}

.nav-link.brand-logo {
  background-color: white !important;
  /*padding: 0 !important;*/
}

.navbar-collapse {
  /*position: ? !important;*/
}

.navbar {
  /*margin-bottom: 6px;*/
  /*padding: 0 4px;*/
  background-color: #ffffff;
  line-height: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0.95 !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

.modal.fade .modal-dialog {
  -moz-transition: none !important;
  -o-transition: none !important;
  -webkit-transition: none !important;
  transition: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
}

a {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #393939;
  transition: all 0.3s;
  text-decoration: none;
}

a:hover {
  /*color: #0076ff;*/
  color: #006cd6;
  text-decoration: none;
}

.badge {
  margin-right: 2px !important;
}

.badge-edit-refill {
  padding: 13px 4px 0 6px;
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.4);
}

.badge-em-opaque {
  color: rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.2) !important;
  padding-left: 1px;
}

.badge-em {
  padding: 0;
  display: block;
  margin: 0;
  /*border: rgba(203, 203, 203, 0.73) 1px solid;*/
  box-shadow: rgba(203, 203, 203, 0.47) 1px 2px;
  border-radius: 4px;
  text-align: center;
  background-color: rgba(213, 213, 213, 0.11);
  font-size: 0.7rem;
}

.light-badge-em {
  background-color: rgba(0, 0, 0, 0.2);
  /*padding-bottom: 4px;*/
}

.custom-link-hover {
  display: inline-block !important;
  margin-left: -5px;
  padding: 2px 5px 2px 5px;
}

.custom-link-hover:hover {
  color: #000000;
  box-shadow: 1px 1px 20px 1px rgba(86, 29, 29, 0.1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 3px;
}

.em-drop {
  position: absolute;
  z-index: 1;
}

.z-index-1 {
  z-index: 1 !important;
}

.dropdown-menu-center {
  margin-top: 40px;
  right: auto;
  left: 50% !important;
  -webkit-transform: translate(-50%, 0) !important;
  -o-transform: translate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
}

/*textarea {*/
/*  max-height: 100px !important;*/
/*}*/

.modal-sm {
  max-width: 375px;
}

.pagination li {
  margin: 6px;
}

.important-note {
  font-weight: bolder;
  background-color: #ffca9e !important;
  /*padding-top: 4px !important;*/
  /*padding-bottom: 4px !important;*/
  /*border: rgba(211, 211, 211, 0.66) solid 0.1rem;*/
  /*box-shadow: inset 0 0 20px rgb(255, 255, 255);*/
}

.note-extend {
  transition:
    margin 320ms ease-out,
    background-color 320ms ease-in-out,
    max-height 300ms ease,
    min-height 300ms ease;
  -webkit-transition:
    margin 320ms ease-out,
    background-color 320ms ease-in-out,
    max-height 300ms ease,
    min-height 300ms ease;
  -moz-transition:
    margin 320ms ease-out,
    background-color 320ms ease-in-out,
    max-height 300ms ease,
    min-height 300ms ease;
  -o-transition:
    margin 320ms ease-out,
    background-color 320ms ease-in-out,
    max-height 300ms ease,
    min-height 300ms ease;
  border-radius: 8px !important;
  background: rgba(255, 255, 187, 0.9) !important;
  min-height: 1rem;
  max-height: 2rem;
}

.note-extend:focus {
  /*margin-right: -10px !important;*/
  /*margin-left: -60px !important;*/
  /*margin-top: -10px !important;*/
  margin-bottom: -80px !important;
  /*margin-bottom: -100rem !important;*/
  min-height: 1rem;
  max-height: 11rem;
  background-color: rgba(255, 255, 255, 1) !important;
  /*border: 1px solid #929292 !important;*/
}

.pagination li.active {
  background-color: #aeaeae !important;
  border-radius: 10px;
}

.custom-btn {
  padding: 0 5px;
  margin: 8px 0 0;
  border: 0;
  height: 20%;
  font-size: 12px;
}

/*material icon customizations*/
.btn-icon {
  text-align: center;
  line-height: normal;
  padding: 0 1px;
  margin: 0 1px;
  border-radius: 6px;
}

/*material icon customizations*/
.m-icon-custom {
  /*padding: 3px 5px 2px 5px !important;*/
  /*padding: 1px 3px !important;*/
  padding: 0 2px !important;
  margin: 2px 0;
  display: block;
  font-size: 16px;
}

.custom-nav-btn {
  /*padding: 5px;*/
  margin: 8px 3px 0;
  font-size: 20px;
  background-color: #898989;
}

.btn-yellow {
  color: #313131;
  background-color: #f3da2c;
  /*font-size: 14px;*/
}

.btn:disabled.btn-light-gray {
  background-color: #7a858c !important;
}

.btn:disabled.btn-orange {
  background-color: #90e969 !important;
}

.btn:disabled.btn-red {
  background-color: #ff0011 !important;
}

.btn-lg-icd {
  font-size: 30px;
  font-weight: bold;
  background: #6fec81;
  margin: 0 8px 4px 0 !important;
  padding: 8px;
}
.btn.btn-lg-icd:focus {
  /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;*/
  box-shadow: none !important;
}

.btn {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  text-transform: uppercase;
}

li.list-group-item {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
  /*box-shadow: 5px 5px 20px -4px rgba(0, 0, 0, 0.05);*/
  border: 1px solid #efefef;
  margin: 5px 0;
}
.card.margin-sides {
  margin-left: 8px;
  margin-right: 8px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(227, 227, 227, 0.22);
}
.table-striped > tbody > tr:hover {
  background-color: rgba(250, 190, 119, 0.23);
}

.btn:disabled {
  background-color: #acacac !important;
  color: #ffffff !important;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

.past-due-red {
  background-color: #ffd6cf;
}

.bill-today-green {
  background-color: palegreen;
}

.only-req-yellow {
  background-color: rgba(255, 233, 183, 0.62);
}

.deceased-red {
  color: red !important;
}

.icd-code-card {
  min-height: 150px;
  background-color: #fbfbfb;
  margin: 2px;
  /*box-shadow: 0 0 30px rgba(0, 0, 0, 0.07);*/
  box-shadow: 5px 5px 15px -4px rgba(0, 0, 0, 0.13);
  font-size: 13px;
}

.navbar-toggler {
  background-color: #e2e2e2;
  /*box-shadow: 0 0 20px rgba(0, 0, 0, 0.07);*/
  margin: 8px;
  padding: 1px;
  width: 50px;
  font-size: 18px;
}

.popover {
  z-index: 35001;
  border-radius: 8px !important;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.4);
}

.popover-header {
  border-radius: 8px !important;
  padding: 0 8px;
}

.popover-inner {
  border-radius: 8px !important;
}
.popover-body {
  padding: 2px 2px 6px 2px !important;
}

.tooltip {
  pointer-events: none;
}

/*.col-6 .card {*/
/*padding: 15px;*/
/*}*/

/*div.col-md-6 {*/
/*padding: 8px;*/
/*}*/

/*.col-3 .icd-code-card {*/
/*margin: 0px;*/
/*}*/

div.col-3-no-padding {
  padding: 6px;
}

.other-icd-search-result {
  color: #747474;
  border-radius: 6px;
  border: 1px solid #ececec;
  margin-top: 5px;
  padding: 4px 3px 1px 3px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.01);
}

.drug-search-name {
  /*background: #0084ff !important;*/
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  border: 2px solid gray;
}

.is-fill {
  color: rgb(255, 255, 255);
  background-color: rgb(26, 190, 255);
  /*font-weight: bold;*/
  border: 2px dotted rgb(255, 240, 240);
  border-radius: 6px;
}

.is-delivery {
  color: rgb(10, 0, 0);
  background-color: rgb(227, 227, 227);
  /*font-weight: bold;*/
  border: 2px solid rgb(210, 210, 210);
  border-radius: 6px;
}

.is-call {
  /*color: rgb(25, 117, 0);*/
  background-color: rgb(166, 255, 142);
  border: 2px dotted rgba(78, 81, 79, 0.23);
  /*font-weight: bold;*/
  border-radius: 6px;
}

/*.scroll {*/
/*max-height: 800px;*/
/*overflow-y: auto;*/
/*}*/

/*fixes modal shift issue*/
.modal {
  overflow-y: auto;
}

.modal-dialog {
  position: absolute;
  top: 24px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.modal-open {
  overflow: auto;
}

.modal-open[style] {
  padding-right: 0px !important;
}

.modal-content {
  border-radius: 8px;
}

.drug-list-item li {
  padding: 5px;
  border: 0.5px dotted rgba(78, 81, 79, 0.23);
}

.rc-pagination {
  list-style: none;
  font-size: 12px;
  font-family: "Arial";
  user-select: none;
  padding: 0;
}

.rc-pagination-item {
  cursor: pointer;
  border-radius: 6px;
  min-width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  list-style: none;
  float: left;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin-right: 8px;
}

.rc-pagination-item a {
  text-decoration: none;
  color: #666;
}

.rc-pagination-item:hover {
  border-color: #2db7f5;
  color: #e2edf5;
}

/*.rc-pagination-item:hover a {*/
/*color: #e2edf5;*/
/*}*/

.rc-pagination-item-active {
  background-color: #93c5ff;
  border-color: #2db7f5;
}

.loading-box {
  color: #3b3b3b;
  background-color: #f0f0f0;
  /*width: 300px;*/
  height: 80px;
  border: 5px solid #f0f0f0;
  border-radius: 4px;
  display: flex;
  font-size: 24px;
  /*padding: 15px;*/
}

.loading-pad-100 {
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  align-items: center;
}

.archived-refill-box {
  /*background-color: #f3f3f3 !important;*/
  border: 2px dotted #dedede !important;
}

.gray-box {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #f2f2f2;
}

.gray-box-small {
  padding: 2px 4px 3px 4px;
  background: rgba(254, 254, 254, 0.4);
  border-radius: 6px;
  /*border: 1px inset rgba(254, 254, 254, 0.3);*/
  margin-top: 5px;
}

.padding-8 {
  padding: 8px;
}

.center-padded-6-box {
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
  opacity: 0.5;
}

.capitalize {
  text-transform: capitalize;
}

.icon-low-right {
  opacity: 0.2;
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 18px;
}

.opacity-05 {
  opacity: 0.1 !important;
}

.opacity-1 {
  opacity: 0.2 !important;
}
.opacity-2 {
  opacity: 0.4 !important;
}
.opacity-3 {
  opacity: 0.6 !important;
}
.opacity-4 {
  opacity: 0.8 !important;
}
.opacity-5 {
  opacity: 1 !important;
}

.font-small {
  font-size: 0.8em;
}

.font-lg {
  font-size: 1.3em;
}

.fs-xs {
  font-size: x-small;
}

.fs-xxs {
  font-size: 0.6em;
}

.fs-sm {
  font-size: small;
}

.fs-md {
  font-size: medium !important;
}

.fs-lg {
  font-size: large;
}

.fs-xl {
  font-size: x-large;
}

.fs-xxl {
  font-size: xx-large;
}

.text-lighter {
  color: rgba(0, 0, 0, 0.2);
}

.d-grid {
  display: grid;
}

.bg-lime,
.bg-lime:hover {
  background-color: #32cd32;
}

.bg-warning-br {
  background-color: #ffe067;
}

.bg-lime:focus {
  background-color: #4fd04f;
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.93);
}

/* New Button style for Link patients, use with primary*/
.bg-info-light {
  background-color: #b3f9ff;
}

.bg-info-light:hover,
.bg-info-light:focus {
  background-color: #b3f9ff;
}

.btn-primary:not(:disabled):not(.disabled).active.bg-info-light {
  background-color: #09a8ce;
  font-weight: 500;
}

.bg-gray {
  background-color: #c1c1c1;
}

.today-row.today-row,
tr.today-row {
  font-weight: bold !important;
  background-color: gray !important;
  color: orange !important;
  font-size: 14px;
}

.line-height-1 {
  line-height: 1;
}

.max-height-scroll {
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
  max-height: 82vh;
}

.max-height-5 {
  max-height: 5vh;
}

.max-height-8 {
  max-height: 8vh;
}

.max-height-10 {
  max-height: 10vh;
}

.max-height-25 {
  max-height: 25vh;
}

.max-height-40 {
  max-height: 40vh;
}

.max-height-50 {
  max-height: 50vh;
}

.max-height-70 {
  max-height: 70vh;
}

.vh-7 {
  height: 7vh;
}

.vh-10 {
  height: 10vh;
}

.vh-25 {
  height: 50vh;
}

.vh-50 {
  height: 50vh;
}

.overflow-y-auto {
  overflow-y: auto;
  overflow-x: hidden;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.rounded-6 {
  border-radius: 8px;
}

.rounded-8 {
  border-radius: 8px !important;
}

.rounded-16 {
  border-radius: 16px !important;
}

.box-shadow-0 {
  box-shadow: none !important;
}

.box-shadow-05 {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);
}

.box-shadow-1 {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
}

.box-shadow-2 {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}

.box-shadow-bottom-1 {
  box-shadow: 2px 5px 12px 0 rgba(0, 0, 0, 0.08);
}

.box-shadow-bottom-2 {
  box-shadow: 2px 5px 12px 0 rgba(0, 0, 0, 0.2);
}

.border-dashed-lighter {
  border: 1px dashed #e8e8e8;
}

.bg-gray-transparent {
  background-color: rgba(191, 191, 191, 0.2);
}

.bg-danger-transparent {
  background-color: rgba(250, 116, 128, 0.3);
}

.text-line-through {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.cursor-pointer:hover {
  background-color: #e5e5e5 !important;
}

.bg-calendar-square {
  background-color: rgba(255, 255, 255, 0.1);
}

.border-lighter {
  border: 1px solid rgba(215, 215, 215, 0.4) !important;
}

.border-left-light {
  border-left: 1px solid rgba(215, 215, 215, 0.4) !important;
}

.border-right-light {
  border-left: 1px solid rgba(215, 215, 215, 0.4) !important;
}

::-webkit-scrollbar {
  width: 1vh;
  height: 8px;
  border-radius: 8px;
  background-color: rgba(218, 218, 218, 0.28);
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bebebe;
}

/*!* Buttons *!*/
/*::-webkit-scrollbar-button:single-button {*/
/*  background-color: #eee;*/
/*  display: block;*/
/*  background-size: 10px;*/
/*  background-repeat: no-repeat;*/
/*}*/

/*!* Up *!*/
/*::-webkit-scrollbar-button:single-button:vertical:decrement {*/
/*  border-radius: 8px;*/
/*  height: 12px;*/
/*  width: 16px;*/
/*  background-position: center 4px;*/
/*  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(73, 73, 73)'><polygon points='50,00 0,50 100,50'/></svg>");*/
/*}*/

/*::-webkit-scrollbar-button:single-button:vertical:decrement:hover {*/
/*  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");*/
/*}*/

/*::-webkit-scrollbar-button:single-button:vertical:decrement:active {*/
/*  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");*/
/*}*/

/*!* Down *!*/
/*::-webkit-scrollbar-button:single-button:vertical:increment {*/
/*  height: 12px;*/
/*  width: 16px;*/
/*  background-position: center 2px;*/
/*  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(73, 73, 73)'><polygon points='0,0 100,0 50,50'/></svg>");*/
/*}*/

/*::-webkit-scrollbar-button:single-button:vertical:increment:hover {*/
/*  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");*/
/*}*/

/*.options-btn {*/
/*  background-color: #e0e0e0;*/
/*  color: black;*/
/*  font-size: 16px;*/
/*  margin: 4px 5px;*/
/*  padding: 3px 9px !important;*/
/*  border: 1px solid #e3e3e3;*/
/*}*/

/*.options-btn:hover {*/
/*  background-color: #fefefe;*/
/*  color: #272727;*/
/*  border: 1px solid #696969;*/
/*}*/
